.header {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;

	p,
	span {
		letter-spacing: normal;
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.content {
	padding: 0;

	.or {
		margin: 1rem;
		text-align: center;
	}

	.social_login {
		display: flex;
		justify-content: space-between;
	}

	.fields {
		input {
			width: 70%;
		}

		& > div {
			margin-bottom: 12px;
		}

		.password_container {
			display: flex;

			.passwd_helper_text {
				margin-bottom: 1rem;
				font-size: 0.7rem;
				width: auto;
				padding: 0;
				margin-left: 0;
				height: auto;
			}

			button {
				width: 25%;
				height: 45px;
				margin-left: 0;
			}

			input {
				width: 100%;
			}
		}
	}

	.back {
		height: 35px;
		border-radius: 8px;
		background-color: none;
		margin-left: 10px;
	}
}

.actions {
	justify-content: flex-start;
	margin-top: 2rem;

	p {
		font-size: 0.8rem;
		margin-top: 0;
		margin-bottom: 0;
		line-height: 1.3rem;
		color: #fff;
		font-weight: 600;

		span {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}
