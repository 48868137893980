.header {
	color: #fff;
	font-size: 1.7rem;
	line-height: 2rem;
	padding: 0 0 16px;
	font-weight: 600;

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.content {
	padding: 0;

	div {
		display: flex;
		align-items: baseline;

		button {
			height: 35px;
			border-radius: 25px;
			background-color: #ffffff12;
		}
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}

	.e_access_btn {
		margin-top: 20px;
		margin-right: 10px;
		height: 35px;
		border-radius: 25px;
		background: linear-gradient(90deg, #01bf52 13.13%, #01acbd 90.45%);
		border: 2px solid transparent;
		width: fit-content;

		a {
			height: 35px;
			border-radius: 25px;
			background-color: #000;
			margin-right: 0;
			height: 100%;
			padding: 8px 19px;
		}
	}
}
