@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.header {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.title {
	margin-top: 30px;
	text-align: center;
}

.subTitle {
	font-size: 14px !important;
	text-align: center;
}

.content {
	padding: 16px 0;
	display: flex;

	@include media($phone_all...) {
		flex-direction: column;
		align-items: center;
	}

	button {
		min-width: 106px;
		height: 35px;
		border-radius: 25px;
		background: #4e4bde;
		margin-right: 12px;

		@include media($phone_all...) {
			margin-bottom: 10px;
			margin-right: 0;
			min-width: 80%;
			width: auto;
		}

		&:hover {
			background: #4e4bde;
		}
	}

	.e_access_btn {
		height: 35px;
		border-radius: 25px;
		background: linear-gradient(90deg, #01bf52 13.13%, #01acbd 90.45%);
		border: 2px solid transparent;

		@include media($phone_all...) {
			min-width: 80%;
			width: auto;
		}

		button {
			background-color: #1e1d47;
			margin-right: 0;
			height: 100%;

			@include media($phone_all...) {
				width: 100%;
			}
		}
	}
}

.footer {
	margin-top: 10px;
	text-align: center;

	button {
		margin-top: 10px;
		padding: 10px 40px;
		background: #000;
		border-radius: 50px;

		&:hover {
			background: #fff;
			color: #000;
		}
	}
}
