.email_verification {
	padding: 8px 8px 0;

	h1 {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 30px;
		margin-bottom: 8px;
	}

	p {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 16px;
		letter-spacing: normal;
	}

	input {
		text-align: center;
	}

	.actions {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		button {
			flex: 1;
			margin: 0 4px;
			border-radius: 40px;
		}
	}
}
