.content {
	padding: 0;

	.fields {
		input {
			width: 82%;
		}

		.row {
			display: flex;
			width: 90%;

			.last_name,
			.first_name {
				width: 100%;
				margin-bottom: 12px;
			}
		}

		.email_input {
			margin-bottom: 12px;
		}

		.password_container {
			display: flex;
			margin-bottom: 12px;

			button {
				height: 45px;
			}

			input {
				width: 100%;
			}

			.form_helper {
				width: 100%;
			}
		}
	}
}

.title {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;
	margin-bottom: 2rem;

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;
		letter-spacing: 0.1rem;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}
