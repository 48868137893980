@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.fraud_dialog {
	.dialog_paper {
		background-color: transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: none;

		@include media($phone_all...) {
			margin: 10px;
		}

		img {
			position: relative;
			z-index: 10;
			width: 340px;
			margin-bottom: 30px;

			@include media($phone_all...) {
				width: 200px;
				margin-bottom: 30px;
			}
		}

		.content {
			position: relative;
			z-index: 10;
			padding-left: 10%;
			padding-right: 10%;
			overflow-y: auto;

			@include media($phone_all...) {
				top: -20px;
			}
		}
	}

	.detail_dialog {
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		border-radius: 1rem !important;
		position: relative;
		height: 490px;
		width: 580px;
		margin-top: -460px;

		@include media($phone_all...) {
			width: 100%;
		}
	}

	h1 {
		letter-spacing: normal;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 46px;
		color: #fff;
		text-align: center;
		margin-bottom: 5px;
	}

	p {
		letter-spacing: normal;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 25px;
		color: #fff;
		margin-bottom: 10px;

		@include media($phone_all...) {
			font-size: 16px;
		}

		span {
			color: #01b6df;
			font-weight: 600;
		}
	}

	.controls {
		display: flex;
		justify-content: center;
		margin-top: 36px;

		button {
			background-color: #4e4bde;
			border-radius: 25px;
			width: 106px;
			height: 35px;
		}
	}
}
