@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.header {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.75rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.content {
	padding: 10px 0;
	display: flex;
	align-items: center;

	button {
		min-width: 106px;
		height: 35px;
		background: #4e4bde;
		border-radius: 25px;
		margin-left: 12px;

		@include media($phone_all...) {
			display: none;
		}
	}
}

.message {
	letter-spacing: normal;
	font-size: 14px;
}

.green {
	color: #3cbb5b;
	margin-bottom: 10px;
}

.actions {
	display: flex;
	justify-content: flex-end;

	.check_btn {
		min-width: 106px;
		height: 35px;
		background: #4e4bde;
		border-radius: 25px;
		margin-top: 10px;

		@include media('>phone') {
			display: none;
		}
	}

	.back {
		height: 35px;
		border-radius: 25px;
		background-color: #ffffff12;
		float: right;
		margin-top: 10px;
		margin-left: 12px;
	}
}
