@import 'Variable';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.transactions_table {
	height: auto;
	margin: 0 !important;
	border-radius: 20px;
	position: relative;
	padding: 0 20px;

	& > h4 {
		margin-left: 30px !important;
	}

	@include media($phone_all...) {
		width: 100% !important;
	}

	table {
		width: 97%;
		margin-left: 10px;
		font-size: 1.1rem;
		text-align: left;
		background: transparent;
		padding: 0.5rem;
		border-collapse: collapse;

		.center {
			text-align: center;
		}

		th {
			color: #fff;
			font-family: var(--font-manrope);
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: 17px;
			letter-spacing: -0.18px;
			background: transparent;
			text-transform: uppercase;
			border: none;
			padding: 0;
			opacity: 0.6;
			border: none;
			text-align: left;

			span,
			svg {
				color: #fff !important;
				line-height: 1.6rem;
			}

			@include media($phone_all...) {
				font-size: 14px;
				letter-spacing: normal;
				line-height: 18px;
			}
		}

		td {
			color: #fff;
			padding: 0;
			border: none;
			font-size: 15px;
			text-align: left;

			@include media($phone_all...) {
				font-size: 12px;
				letter-spacing: normal;
				line-height: 18px;
			}

			img {
				height: 18px;
				margin-right: 4px;
				display: inline-block;
			}

			svg {
				margin: 0;
				margin-right: 5px;
			}

			a {
				color: #59b7d8;
				text-decoration-line: underline;
				display: block;
				padding: 0.5rem 0;
			}
		}
	}

	.pagination {
		* {
			color: #ffffff;
		}

		button {
			&:disabled {
				* {
					color: #ffffff52;
				}
			}
		}
	}
}
