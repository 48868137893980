@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.header {
	color: #fff;
	font-size: 26px;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;
	text-align: center;

	@include media($phone_all...) {
		padding-right: 28px;
	}

	p,
	span {
		letter-spacing: normal;
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.25rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;
		text-align: center;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.content {
	padding: 0;

	.fields {
		.emailField {
			> div {
				background-color: transparent;
			}
		}

		input {
			width: 100%;
			text-align: center;
		}

		& > div {
			margin-bottom: 24px;
		}
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: space-around;

		button {
			min-width: 160px;
			font-weight: 600;
		}
	}
}
