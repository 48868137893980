@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dialog {
	.dialog_paper {
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		border-radius: 1rem !important;
		max-width: 600px !important;
		position: relative;

		@include media($phone_all...) {
			margin: 16px;
		}

		& > button {
			position: absolute;
			top: 10px;
			right: 6px;
			z-index: 101;

			& > svg {
				height: 15px;
			}
		}
	}

	.container {
		color: #fff;
		font-style: normal;
		padding: 15px 50px 25px !important;

		@include media($phone_all...) {
			padding: 15px 10px 25px !important;
		}

		h2 {
			letter-spacing: normal;
			font-weight: 400;
			font-size: 22px;
			line-height: 33px;
			margin-bottom: 25px;
			width: 94%;

			@include media($phone_all...) {
				line-height: 23px;
				margin-bottom: 10px;
			}

			strong {
				color: #50e3c2;
			}
		}

		.info {
			display: flex;
			align-items: center;

			@include media($phone_all...) {
				margin-bottom: 1rem;
			}

			p {
				letter-spacing: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				margin-left: 16px;

				@include media($phone_all...) {
					line-height: 20px;
				}

				span {
					&.green {
						color: #08f996;
					}

					&.aqua {
						color: #50e3c2;
					}
				}
			}
		}

		.center {
			display: flex;
			justify-content: space-between;
			margin-top: 30px;

			@include media($phone_all...) {
				display: initial;
			}

			& > div {
				border-radius: 15px;
				padding: 20px 15px;
				width: 48%;

				@include media($phone_all...) {
					width: 100%;
				}

				p {
					letter-spacing: normal;
					margin-top: 4px;
				}
			}

			.left {
				background-color: #292375;

				h6 {
					font-weight: 600;
					font-size: 11px;
				}
			}

			.green {
				color: #50e3c2;
			}

			.right {
				background-color: #11113a;
				position: relative;
				padding-bottom: 18px;

				h6 {
					font-weight: 600;
					font-size: 11px;
				}

				span {
					margin-right: 5px;

					img {
						width: 25px;
						position: relative;
						display: inline-block;
						top: 5px;
						right: initial;
					}
				}

				img {
					position: absolute;
					width: 32px;
					height: 35px;
					top: 4px;
					right: 4px;
				}
			}
		}

		.buttons {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;

			@include media($phone_all...) {
				line-height: 1rem;
			}

			a {
				background: rgba(78, 75, 222, 0.25);
				border-radius: 10px;
				width: 48%;
				font-size: 14px;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;

				@include media($phone_all...) {
					padding-left: 0.75rem;
				}

				svg {
					font-size: 16px;
					margin-left: 10px;

					@include media($phone_all...) {
						margin-right: 20px;
					}
				}
			}

			button {
				background: rgba(78, 75, 222, 0.25);
				border-radius: 10px;
				width: 48%;

				@include media($phone_all...) {
					line-height: 1rem;
				}

				svg {
					font-size: 16px;
					margin-left: 10px;
				}
			}
		}
	}
}
