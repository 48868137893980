@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.token_sale_page {
	margin-top: 2rem;
	margin-bottom: 6rem;
	max-width: 1080px !important;
	position: relative;
	z-index: 2;
	display: flex !important;
	flex-direction: column;

	@include media($phone_all...) {
		max-width: 90% !important;
	}

	.completeProfile {
		background: #000;
		margin: 15px 0 15px;
		padding: 10px 20px;
		border-radius: 10px;

		p {
			font-size: 14px;
		}

		a {
			cursor: pointer;
		}
	}

	.top_detail_list {
		.saleClosed {
			background: #161622;
			padding: 20px 20px;
			width: 100%;
			margin: 0 auto 5px auto;
			text-align: center;

			h3 {
				display: block;
				font-size: 35px;
				line-height: 40px;
				font-weight: 600;
				letter-spacing: 0;
				margin-bottom: 10px;
				display: inline-block;
				background: linear-gradient(45deg, #6b3bfa, #f11e76);
				background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;

				@include media($phone_all...) {
					font-size: 30px;
				}
			}

			span {
				display: block;
				line-height: 28px;
				font-size: 20px;

				@include media($phone_all...) {
					font-size: 17px;
					line-height: 22px;
				}
			}

			.profile {
				margin: 15px auto 0;
				background: #000;
				padding: 20px 40px;
				border-radius: 10px;
				max-width: 80%;
				width: 100%;

				@include media($phone_all...) {
					max-width: 100%;
					padding: 20px 20px;
				}

				p {
					color: #fff;
				}

				a {
					cursor: pointer;
				}
			}

			.completeProfile {
				background: #000;
				margin: 15px auto 0;
				padding: 20px 40px;
				border-radius: 10px;
				max-width: 80%;
				width: 100%;

				@include media($phone_all...) {
					max-width: 100%;
					padding: 20px 20px;
				}

				p {
					font-size: 18px;
					color: #fff;
					margin-bottom: 10px;

					@include media($phone_all...) {
						font-size: 13px;
						line-height: 17px;
						font-weight: 600;
					}
				}

				a {
					cursor: pointer;
				}

				.inactiveLogin {
					font-size: 0.8rem;
					font-weight: 300;
					border-radius: 1.4rem;
					border: 0.1rem double transparent;
					background-image: linear-gradient(#152d53, #152d53),
						radial-gradient(circle at left top, #016eda, #d900c0);
					background-origin: border-box;
					background-clip: padding-box, border-box;
					padding: 0.5rem 1.5rem;
					color: #fff;
					margin: 20px auto 0;
					max-width: 200px;
					cursor: pointer;
				}
			}
		}
	}

	.history {
		padding-top: 0px !important;

		a {
			cursor: pointer;
		}
	}

	.headerImg {
		background: #000;
		display: flex;
		margin-top: 5px;
		padding: 0 !important;
		border-radius: 25px;
		width: 100%;
		height: 80px;
		max-height: 80px;
		overflow: hidden;
		filter: drop-shadow(0px 60px 60px rgba(0, 0, 0, 0.25));
		text-align: center;

		@include media($phone_all...) {
			background: transparent;
			height: 75px;
			margin-top: 18px;
		}

		img {
			padding: 0;
			margin: 0;
			height: 363px;
			width: 100%;
			top: -35px;
			position: absolute;

			@include media($phone_all...) {
				top: 0;
			}
		}
	}

	.increaseCTA {
		font-size: 18px;
		margin: 10px 0 5px 0;
		text-align: center;
		width: 100%;

		@include media($phone_all...) {
			margin-top: 0px;
			font-size: 12px;
		}
	}

	.stageInfo {
		font-size: 15px !important;
		margin-bottom: 10px;
		display: none;
	}

	.bigProgress {
		display: flex;
		width: 80%;
		padding: 0 !important;
		margin: 5px auto 0;

		@include media($phone_all...) {
			margin-top: 0;
			width: 90%;
		}

		.stageProgress {
			width: 100%;
			background: #000;
			height: auto;
			border-radius: 0;
			padding: 5px;
			position: relative;
			text-align: center;

			@include media($phone_all...) {
				padding: 0px;
			}
		}

		.progressMeter {
			position: absolute;
			left: 0;
			bottom: 0;
			top: 0;
			right: 0;
			background: forestgreen;
		}

		.progressContainer {
			position: relative;
			z-index: 1;

			span {
				font-size: 18px;
				font-weight: 600;

				@include media($phone_all...) {
					font-size: 16px;
				}
			}
		}
	}

	.column_holder {
		display: flex;
	}

	.column_holder {
		display: flex;
	}

	.recap {
		margin-top: 0px;

		.accordian {
			@include media($phone_all...) {
				margin: 0;
			}

			.faqHeader {
				background: #152d53;

				> div > div > p {
					font-weight: 600;
					width: 90%;
					font-size: 18px;
					margin: 10px;
					color: #74bceb;
				}

				svg {
					color: #fff;
					margin-right: 10px;
				}
			}

			> :first-child {
				border-radius: 20px 20px 0 0;
			}

			> :last-child {
				border-radius: 0 0 20px 20px;
			}

			.faqContent {
				background: rgba(0, 0, 0, 0.35);
				padding: 10px 20px;
				border-radius: 0;

				@include media($phone_all...) {
					padding: 5px;
				}

				p {
					font-weight: 600;
					color: #fff;
					line-height: 20px;
					width: 90%;
					margin: 10px;
				}

				ul,
				ol {
					color: #fff;
					margin: 0 0 20px 40px;
					max-width: 90%;

					li {
						a {
							text-decoration: underline;
						}
					}
				}

				ol {
					li {
						display: list-item;
						list-style: decimal !important;
					}
				}
			}
		}

		.shadow {
			margin-top: 10px;
			margin-right: 0;
			padding: 30px 0;
			background: rgba(0, 0, 0, 0.55);
			border-radius: 20px;

			@include media($phone_all...) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		& > * {
			margin: 20px 30px;
		}

		.nextSteps {
			padding: 0 30px;
		}
	}

	h1 {
		margin-top: 20px;
		font-size: 32px;
		font-weight: 600;
	}

	.fomo {
		position: absolute;
		max-width: 450px;
		width: 100%;
		bottom: 20px;
		margin-left: 50%;
		transform: translateX(-50%);

		@include media($phone_all...) {
			bottom: initial !important;
			top: 0px !important;
		}
	}

	h2 {
		margin: 30px 0 0 30px !important;
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0;
		display: inline-block;
		background: linear-gradient(45deg, #6b3bfa, #f11e76);
		background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.ended {
		margin-top: 0 !important;
	}

	h4 {
		margin: 0 0 10px 10px !important;
		font-weight: 600;
		letter-spacing: 0;
		display: inline-block;
		background: linear-gradient(45deg, #6b3bfa, #f11e76);
		background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	p {
		a {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	ul {
		margin: 25px 0;
		padding-left: 15px;

		li {
			font-size: 17px;
			list-style: disc;
		}
	}

	ol {
		margin: 25px 0;
		padding-left: 15px;

		li {
			font-size: 17px;
			list-style: disc;
		}
	}

	.widgetCont {
		display: inline-block;
		background: #161622
			url('https://app.film.io/static/media/start-back.853f85c3a37b2aa37a88.png')
			no-repeat;
		background-size: cover;
		border-radius: 1.5rem;
		padding-top: 0;
		margin-top: 0px;
		width: 100%;
		position: relative;
		z-index: 1;

		@include media($phone_all...) {
		}

		.chainLogo {
			display: flex;
			position: relative;
			top: -8px;
			background: #152d53;
			border-radius: 25px;
			object-fit: contain;
			padding: 0.75rem;
			justify-content: center;
			align-items: center;
			max-width: 125px;
			margin: 0 auto;

			img {
				display: inline-block;
				width: 1.25rem;
				height: 1.25rem;
			}
		}

		.increaseCTA,
		.stageCTA {
			font-size: 15px;
			margin: 10px 0 5px 0;
			text-align: center;
			width: 100%;

			@include media($phone_all...) {
				font-size: 12px;
			}
		}

		.stageCTA {
			font-size: 18px;
		}
	}

	.preSaleWidget {
		margin-top: 0px;
		padding-top: 10px !important;

		@include media($phone_all...) {
			padding-top: 20px;
		}

		h1 {
			margin-top: 10px;
			margin-bottom: 30px;

			@include media($phone_all...) {
				margin-top: 0px;
				text-align: center;
			}
		}

		.shadow {
			margin-top: 0px;
			padding: 30px 0;
			background: rgba(0, 0, 0, 0.55);
			border-radius: 20px;

			@include media($phone_all...) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.widget {
			text-align: center;
			max-width: 474px;
			padding: 0;
			border-radius: 25px;
			min-height: 760px;
			height: 100%;
			overflow: hidden;

			button {
				margin-top: 30px;
				padding: 10px 40px;
				background: #f00;
				border-radius: 50px;

				&:hover {
					background: #fff;
					color: #000;
				}
			}
		}

		.last_transaction_info {
			margin-bottom: 25px;

			.txnCard {
				margin-top: 15px;
				padding: 10px 20px;
				border-radius: 10px;
				background: #4e4bde;

				p {
					color: #fff;
					display: flex;
					align-items: center;

					span {
						margin-right: 3px;
					}
				}
			}

			p {
				color: #fff;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: normal;

				span {
					font-weight: 700;
				}

				svg {
					margin: 0 3px;
				}

				img {
					height: 18px;
					margin-left: 5px;
					margin-right: 5px;
					margin-bottom: -3px;
				}

				a {
					color: #59b7d8;
					font-size: 15px;
					font-weight: 400;
					line-height: 24px;
					text-decoration-line: underline;
				}
			}
		}
	}
}

.message_modal {
	p {
		font-size: 16px !important;
		text-align: left !important;
		letter-spacing: normal;
	}
}

.emergency_text {
	font-size: 14px;
	margin-bottom: 30px;

	p {
		font-size: 14px !important;
		text-align: left !important;
		line-height: 20px !important;
		text-align: left !important;
	}

	.link {
		margin-top: -15px;
	}
}

.rounded {
	border-radius: 20px;
}
