@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.content {
	padding: 0;

	.back {
		font-size: 0.9rem;
		font-weight: 600;
		background: #2e2c6a !important;
		border-radius: 25px !important;
		color: #fff !important;
		padding: 0.5rem 2.5rem !important;
		float: right;

		&:disabled {
			color: #ffffff96 !important;
		}
	}

	.or {
		margin: 1rem;
		text-align: center;
	}

	.social_login {
		display: flex;
		justify-content: space-between;
		margin-right: 64px;
	}

	.recaptcha {
		margin-bottom: 15px;
	}

	.fields {
		input {
			width: 100%;
		}

		.row {
			display: flex;
			justify-content: space-evenly;
			width: 100%;

			.last_name,
			.first_name {
				width: 95%;
				margin-bottom: 16px;
			}

			.last_name {
				position: relative;
				right: -7px;
			}
		}

		.email_input {
			margin-bottom: 24px;
			width: 100%;
		}

		.password_container {
			display: flex;
			margin-bottom: 12px;

			button {
				height: 45px;
			}

			input {
				width: 100%;
			}

			.form_helper {
				width: 100%;
			}
		}
	}
}

.title {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;
	margin-bottom: 0;

	@include media($phone_all...) {
		margin-bottom: -0.5rem;
	}

	@include media($phone_all...) {
		margin-bottom: -0.5rem;
	}

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.25rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;
		letter-spacing: normal;

		@include media($phone_all...) {
			margin-top: 0.25rem;
		}

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.agree {
	margin: 20px 30px 10px;
	padding-left: 5px;

	@include media($phone_all...) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@include media($phone_all...) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	p {
		letter-spacing: normal;
		font-size: 10px;
		font-weight: 400;
		margin: 0;
		line-height: 18px;
		text-align: center;
		color: rgba(255, 255, 255, 0.5);
	}

	a {
		color: rgba(255, 255, 255, 0.5);
		cursor: pointer;
		text-decoration: underline;
	}

	svg {
		color: #fff;
		font-size: 18px;
	}

	.newsletters {
		span {
			letter-spacing: 1px;
			font-size: 0.7rem;
			font-weight: 300;

			svg {
				color: #fff;
				font-size: 14px;
			}
		}
	}
}

.otherLogin {
	margin-top: 24px;

	.wallet_cnct,
	.wallet_mobile_cnct {
		text-align: center;
		font-size: 0.8rem !important;
		border-radius: 25px !important;
		border: 0.1rem double transparent !important;
		background-image: radial-gradient(circle at left top, #fe9dd9, #8ee6e2),
			radial-gradient(circle at left top, #d900c0, #016eda) !important;
		background-origin: border-box !important;
		background-clip: padding-box, border-box !important;
		margin: 10px 0 15px 0;
		width: 100%;
		color: #000;
		font-size: 15px;
		display: flex !important;
		align-items: center;
		justify-content: center;
		font-weight: 600;

		svg {
			color: #000;
			font-size: 14px;
		}

		.walletBtn {
			position: relative;

			svg {
				color: #000;
				margin-right: 10px;
				font-size: 14px;
			}
		}
	}

	.wallet_mobile_cnct {
		display: flex;
		font-size: 13px;

		img {
			position: relative;
			width: 14px;
			margin-right: 10px;
		}

		p {
			font-size: 0.8rem !important;
			font-weight: 600;
		}
	}
}
