@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.onRamp {
	border: none;

	@include media($phone_all...) {
		width: 100% !important;
	}
}
