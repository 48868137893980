@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.connectDialog {
	.dialogContainer {
		background: rgba(0, 0, 0, 0.4);

		.dialogPaper {
			width: 100%;
			max-width: 384px;
			position: relative;
			border: 1px solid rgba(255, 255, 255, 0.25);
			background: linear-gradient(
				180deg,
				rgba(20, 20, 22, 0.5) 0%,
				rgba(0, 0, 0, 0.5) 100%
			);
			border-radius: 32px;
			color: #fff;
			padding: 32px 24px;
			backdrop-filter: blur(5px);
			max-height: 240px;
			height: 100%;
			@include media($phone_all...) {
				max-width: 400px;
				margin: 0.5rem;
			}

			.closeBtn {
				position: absolute;
				right: 24px;
				top: 32px;
				z-index: 100;
			}

			.body {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				gap: 32px;
				width: 100%;
				max-height: 161px;
				padding-top: 15px;
				.header {
					max-width: 100%;
					color: #fff;
					font-size: 2rem;
					line-height: 2.3rem;
					font-weight: 600;
					text-align: center;
					height: 33px;
					display: flex;
					justify-content: center;
					align-items: center;
					@include media($phone_all...) {
						font-size: 1.75rem;
					}
				}

				.otherLogin {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					gap: 16px;
					align-items: center;
					.walletCnct,
					.walletMobileCnct {
						text-align: center;
						font-size: 0.8rem !important;
						border-radius: 25px !important;
						border: 0.1rem double transparent !important;
						background-image: radial-gradient(
								circle at left top,
								#fe9dd9,
								#8ee6e2
							),
							radial-gradient(circle at left top, #d900c0, #016eda) !important;
						background-origin: border-box !important;
						background-clip: padding-box, border-box !important;
						width: 100%;
						color: #000;
						font-size: 15px;
						display: flex !important;
						align-items: center;
						justify-content: center;
						font-weight: 600;
						height: 40px;

						svg {
							color: #000;
							font-size: 14px;
						}

						.walletBtn {
							position: relative;

							svg {
								color: #000;
								margin-right: 10px;
								font-size: 14px;
							}
						}

						&:disabled {
							opacity: 0.6;
							color: #000;
						}
					}

					.walletMobileCnct {
						display: block;
						font-size: 13px !important;
						color: #000 !important;
						font-weight: 600 !important;

						.metamaskbutton {
							font-size: 20px;
							margin: 10px 5px 15px;
							background: #f6851b !important;
							border: 2px solid rgba(255, 255, 255, 0.3);
							text-align: center;
							cursor: pointer;
							padding: 0.75rem 1.5rem;
							border-radius: 25px;
							color: #fff;
							display: none;

							&:hover {
								opacity: 0.75;
							}
						}

						a {
							display: block;
							width: 100%;
							p {
								display: inline-block;
								font-size: 13px !important;
								font-weight: 600 !important;
								color: #000 !important;
							}
						}

						img {
							width: 26px;
							width: 14px;
							margin-right: 10px;
							position: relative;
							top: 1px;
							display: inline;
						}

						@include media($phone_all...) {
							display: block !important;
						}
					}
				}
			}
		}
	}
}
