.arrow_icon {
	color: #7d7d7d;
	width: 20px;
	height: 20px;
	transform: rotate(90deg);
	transition: transform 0.3s ease;
}

.arrow_icon.rotate {
	transform: rotate(270deg);
}
.click_button {
	&:hover {
		background: transparent !important;
	}
}

.icon_list {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	img {
		border-radius: 4px;
		width: 24px;
		height: 24px;
	}
}
.flex_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
