@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.onboard_join {
	margin-top: 122px;
	position: relative;
	z-index: 1;
	background: rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	padding: 10px;
	filter: drop-shadow(0px 60px 60px rgba(0, 0, 0, 0.25));
	max-width: 500px !important;

	@include media($phone_all...) {
		margin-top: 70px;
		padding: 1rem;
		border-radius: 0;
	}

	.footer_text {
		letter-spacing: normal;
		font-size: 0.7rem;
		line-height: 1rem;
		color: #666;
		padding: 0;
		margin-top: 0;
		margin-bottom: 10px;
		text-align: center;

		@include media($phone_all...) {
			line-height: 0.8rem;
			margin-top: 0.5rem;
		}
	}

	.right {
		display: flex !important;
		flex-direction: column;

		.recaptcha {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.agree {
			margin-top: 10px;
			margin-bottom: 20px;
			margin-left: 15px;

			@include media($phone_all...) {
				margin-bottom: 10px;
			}

			p {
				letter-spacing: 1px;
				font-size: 0.7rem;
				font-weight: 300;
				margin: 0;
				line-height: 15px;
			}

			a {
				color: #fff;
				cursor: pointer;
				text-decoration: underline;
			}

			svg {
				color: #fff;
				font-size: 18px;
			}
		}

		.metamaskbutton {
			font-size: 20px;
			margin: 10px 5px 15px;
			background: #f6851b !important;
			border: 2px solid rgba(255, 255, 255, 0.3);
			text-align: center;
			cursor: pointer;
			padding: 0.75rem 1.5rem;
			border-radius: 25px;
			color: #fff;

			@include media($phone_all...) {
				display: block !important;
			}

			&:hover {
				opacity: 0.75;
			}
		}

		@include media($phone_all...) {
			padding-top: 0 !important;
			padding-left: 16px !important;
		}

		.hero_container {
			margin-bottom: 0;
			padding: 1rem;
			padding-bottom: 0.5rem;
			overflow: hidden;

			@include media($phone_all...) {
				max-height: 100px;
				overflow: hidden;
			}
		}

		img {
			border-radius: 8px;
			width: 100%;
		}

		.logo {
			margin-top: 0;
			width: 100%;
			display: flex;
			justify-content: left;
			align-items: center;

			@include media($phone_all...) {
				margin-top: 2rem;
			}

			img {
				max-width: 240px;

				@include media($phone_all...) {
					max-height: 100%;
					max-width: 100%;
				}
			}
		}

		ul {
			li {
				display: block;
				width: 100%;
				font-size: 0.9rem;
				font-weight: 600;
				list-style: disc;
			}
		}

		.intro {
			padding: 0 15px;
			margin-top: 18px;
		}

		.title {
			font-size: 35px;
			margin-top: 16px;
			margin-bottom: 0px;
			font-weight: 600;

			@include media($phone_all...) {
				font-size: 30px;
				margin-bottom: 0px;
			}
		}

		.subtitle {
			font-size: 15px;
			margin-top: 0px;
			margin-bottom: 8px;
			font-weight: 300;
		}

		.subtext {
			@extend .subtitle;
			color: #00baef;
			font-size: 20px;
			font-weight: 600;
			line-height: 15px;

			@include media($phone_all...) {
				margin: 0;
				line-height: 20px;
			}
		}

		.fields {
			width: 100%;
			padding: 0 15px;
			margin-top: 16px;

			@include media($phone_all...) {
				width: 97%;
				margin-left: 1rem;
			}

			input {
				width: 100%;
			}

			.row {
				display: flex;
				width: 100%;

				.last_name,
				.first_name {
					width: 95%;
					margin-bottom: 12px;
				}

				.last_name {
					margin-left: 0.7rem;

					@include media($phone_all...) {
						margin-left: 0.4rem;
					}
				}
			}

			.email_input {
				margin-bottom: 12px;
			}

			.password_container {
				display: flex;
				margin-bottom: 12px;

				button {
					height: 45px;
				}

				input {
					width: 100%;
					margin-bottom: 10px;
				}

				.form_helper {
					width: 100%;
				}
			}
		}
	}

	button {
		margin-bottom: 20px;
	}
}
