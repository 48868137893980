.content {
	h6 {
		font-weight: 700;
		font-size: 20px;
	}

	p {
		margin: 10px 0 24px;
		opacity: 0.5;
		font-size: 16px;
	}

	.infoLink {
		display: block;
		font-size: 0.9rem;
		font-weight: 600;
		text-align: center;
		background: #4e4bde !important;
		border-radius: 25px !important;
		color: #fff !important;
		padding: 0.5rem 2.5rem !important;
		margin: 8px 0;

		&:disabled {
			color: #ffffff96 !important;
		}
	}

	button {
		width: 100%;
		font-weight: 600;
		margin: 8px 0;
	}
}
