@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.nc_wihout_magic_warning {
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: center;
	top: 78px;

	.message {
		border-radius: 10px;
		background: #01acbd;
		margin-bottom: 35px;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		text-align: center;
		padding: 12px;
		display: flex;
		justify-content: center;
		cursor: pointer;
		padding: 10px 25px;

		@include media($phone_all...) {
			line-height: 16px;
			text-align: left;
			margin-bottom: 20px;
		}

		svg {
			margin-right: 6px;
			font-size: 15px;

			@include media($phone_all...) {
				margin-right: 10px;
			}
		}
	}
}
