@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

@mixin flex-box(
	$flex-direction: row,
	$justify-content: flex-start,
	$align-items: flex-start
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

@media screen and (max-width: 600px) {
	.dialogContainer {
		display: flex !important;
		justify-content: center;
		align-items: flex-start;
	}

	.dialogPaper {
		margin: 0px !important;
		margin-top: 10% !important;
		min-width: 250px !important;
		max-width: 350px !important;
		width: -webkit-fill-available !important;
		background: #060607;
		border-radius: 8px;
		border: none;
		@include media($phone_all...) {
			max-width: 400px !important;
		}
	}
}

.funPurchase {
	z-index: 997 !important;

	.dialogPaper {
		position: relative;
		width: 450px;
		background: rgba(20, 20, 22, 1);
		border-radius: 30px;
		color: #fff;
		margin-top: 12%;
		height: clamp(382px, 100%, 744px);
		height: fit-content;
		.closeIcon {
			position: absolute;
			right: 21px;
			top: 21px;
			cursor: pointer;

			@include media($phone_all...) {
				right: 5px;
				top: 5px;
			}
		}

		.closeMobile {
			display: none;

			@include media($phone_all...) {
				display: block;
				width: 303px !important;
				margin-left: -17px;
				margin-top: 16px;

				button {
					width: 100%;
					background: #152d53;
					border-radius: 10px;
					height: 46px;

					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						border-radius: 10px;
						border: 2px solid transparent;
						border-left-color: #016eda;
						border-right-color: #d900c0;
						background: linear-gradient(270deg, #d900c0 12.82%, #016eda 75.21%);
						-webkit-mask: linear-gradient(#fff 0 0) padding-box,
							linear-gradient(#fff 0 0);
						-webkit-mask-composite: destination-out;
						mask-composite: exclude;
					}
				}
			}
		}

		.dialogContent {
			padding: 16px 0px;
			overflow: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
			input {
				background: transparent;
				outline: none;
				border: none;
				font-family: Titillium Web;
				font-size: 1.875rem;
				font-weight: 600;
				line-height: 36px;
				text-align: left;
				color: #fff;
				flex: 1 1 50%;
				max-width: 50%;
				&::placeholder {
					font-size: 1.25rem;
				}
				@include media($phone_all...) {
					font-size: 1.4rem;
				}
			}
			/* Chrome, Safari, Edge, Opera */
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			input[type='number'] {
				-moz-appearance: textfield;
			}
			.tabHeader {
				position: relative;

				.closeButton {
					cursor: pointer;
					position: absolute;
					top: 10px;
					right: 24px;
					z-index: 99999;
				}
			}

			.tabContent {
				padding: 16px 41px;
				@include media($phone_all...) {
					padding: 10px 21px;
				}
				.successBox {
					min-height: 99px;
					border-radius: 8px;
					background: rgba(39, 39, 39, 1);
					width: 100%;
					padding: 16px 15px;
					@include flex-box(column, center, center);
					@include media($phone_all...) {
						padding: 10px;
					}
					gap: 5px;
					&.text {
						font-family: Titillium Web;
						font-size: 1.25rem;
						font-weight: 400;
						line-height: 36px;
						text-align: center;
					}

					h1 {
						font-family: Titillium Web;
						font-size: 1.875rem;
						font-weight: 600;
						line-height: 36px;
						text-align: center;

						span.green {
							color: rgba(8, 249, 150, 1);
						}
						@include media($phone_all...) {
							font-size: 1.4rem;
						}
					}
				}

				.buttonSuccessBox {
					width: 100%;
					@include flex-box(row, center, center);
					gap: 14px;
				}
				.buttonSuccessGrid {
					width: 100%;
					@include flex-box(column, center, center);
					gap: 14px;
				}

				.header {
					padding: 0px;
					margin-bottom: 33px;
					@include flex-box(row, space-between, center);
					row-gap: 1.5px;

					.flexSection {
						padding: 0px;
						flex: 1 1 30%;
						max-width: 30%;

						&:last-child {
							@include flex-box(row, flex-end, center);
						}

						&:first-child {
							@include flex-box(row, flex-start, center);
						}

						&:nth-child(2) {
							@include flex-box(row, center, center);
						}
					}
				}

				h3 {
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
				}

				.boxs {
					@include flex-box(column, space-between, center);
					gap: 13px;
					width: 100%;

					.onRamp {
						border: none;

						@include media($phone_all...) {
							width: 100% !important;
						}
					}

					.rowFlex {
						@include flex-box(row, space-between, flex-end);
						width: 100%;
					}

					.text {
						font-family: Titillium Web;
						font-size: 14px;
						font-weight: 300;
						line-height: 24px;
					}

					h1 {
						font-family: Titillium Web;
						font-size: 1.875rem;
						font-weight: 600;
						line-height: 24px;
						color: rgba(255, 255, 255, 1);
						@include media($phone_all...) {
							font-size: 1.4rem;
						}
					}

					.sideButton {
						width: 89px;
						height: 37px;
						border-radius: 8px;
						font-family: Titillium Web;
						font-size: 1.25rem;
						font-weight: 600;
						line-height: 24px;
						text-transform: uppercase;
						color: rgba(255, 255, 255, 1);
						background: rgba(63, 63, 67, 1);
						@include media($phone_all...) {
							font-size: 1rem;
						}
					}

					.underBox {
						font-family: Titillium Web;
						font-size: 14px;
						font-weight: 300;
						line-height: 24px;
						@include flex-box(row, center, center);
						width: 100%;
					}

					.underBoxError {
						font-family: Titillium Web;
						font-size: 14px;
						font-weight: 700;
						line-height: 24px;
						@include flex-box(row, left, left);
						width: 100%;
					}
				}

				.bottomBox {
					@include flex-box(column, space-between, center);
					width: 100%;
					gap: 13px;

					margin-top: 45px;
					@include media($phone_all...) {
						margin-top: 0px;
					}
					&.bottomBoxCrypto {
						margin-top: 32px;
						@include media($phone_all...) {
							margin-top: 0px;
						}
					}

					.text {
						font-family: Titillium Web;
						font-size: 14px;
						font-weight: 300;
						line-height: 21.29px;
						text-align: center;
						margin-top: 5px;
						width: 300px;
						color: rgba(255, 255, 255, 1);
						@include media($phone_all...) {
							width: 100%;
						}
					}
				}

				.firstBox {
					min-height: 99px;
					border-radius: 8px;
					background: rgba(39, 39, 39, 1);
					width: 100%;
					padding: 16px 15px;
					@include flex-box(column, flex-start, flex-start);
					gap: 19px;
					@include media($phone_all...) {
						gap: 5px;
						padding: 10px;
					}
				}
				.network_box {
					min-height: fit-content;
					border-radius: 8px;
					background: rgba(39, 39, 39, 1);
					width: 100%;
					padding: 16px 15px;
					@include flex-box(row, space-between, center);
					gap: 19px;
					@include media($phone_all...) {
						gap: 5px;
						padding: 10px;
					}
				}
				.secondBox {
					@include flex-box(column, space-between, center);
					row-gap: 1.5px;
					width: 100%;

					& .secondBoxCard {
						min-height: 131.5px;
						border-radius: 8px 8px 0px 0px;
						background: rgba(39, 39, 39, 1);
						width: 100%;
					}

					& .secondBoxCrypto {
						min-height: 91px;
						border-radius: 8px 8px 0px 0px;
						background: rgba(39, 39, 39, 1);
						width: 100%;
						padding: 16px 15px;
						@include flex-box(column, flex-start, flex-start);
						gap: 19px;
						@include media($phone_all...) {
							gap: 5px;
							padding: 10px;
						}
					}

					& div:nth-child(2) {
						min-height: 30px;
						border-radius: 0px 0px 8px 8px;
						background: rgba(39, 39, 39, 1);
						width: 100%;
					}
				}

				.CustomButton {
					flex: 0 0 179px;
					max-width: 100%;
				}
				.loadingButton {
					background: rgba(70, 222, 140, 1);
					opacity: 0.5;
					width: 100%;
					color: rgba(0, 0, 0, 1);
					min-height: 45px;
					height: 45px !important;
					font-family: Titillium Web;
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					flex: 0 0 141px;
					max-width: 100%;
					cursor: progress;
				}
				.agreeButton {
					background: rgba(70, 222, 140, 1);
					width: 100%;
					min-height: 56px;
					border-radius: 8px;
					font-family: Titillium Web;
					font-size: 1.25rem;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					color: rgba(0, 0, 0, 1);

					&.customHeight {
						min-height: 45px;
						height: 45px !important;
						font-family: Titillium Web;
						font-size: 16px;
						font-weight: 600;
						line-height: 24px;
						flex: 0 0 141px;
						max-width: 100%;
					}
					&:disabled {
						background: #324535 !important;
						cursor: default;
					}
					@include media($phone_all...) {
						font-size: 1.1rem;
						min-height: 48px;
					}
				}

				.agreeButtonCrypto {
					background: rgba(70, 222, 140, 0.25);
					width: 100%;
					min-height: 56px;
					border-radius: 8px;
					font-family: Titillium Web;
					font-size: 1.25rem;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					@include media($phone_all...) {
						font-size: 1.1rem;
						min-height: 48px;
					}
				}

				.select {
					width: 100%;
					min-height: 54px;
					border-radius: 8px;
					border: 1px solid rgba(118, 118, 118, 1);
					background: rgba(63, 63, 67, 1);
				}
			}

			.tabsList {
				.card {
					border-radius: 0px 5px 5px 0px !important;
					min-height: 34px !important;
				}

				.crypto {
					border-radius: 5px 0px 0px 5px !important;
					min-height: 34px !important;
				}

				button[aria-selected='true'] {
					background: rgba(21, 45, 83, 1);
					color: rgba(255, 255, 255, 1);
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					height: 34px;
				}

				button[aria-selected='false'] {
					background: rgba(21, 45, 83, 0.5);
					color: rgba(255, 255, 255, 0.5);
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					height: 34px;
				}
			}
		}
	}
}
