@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.ban_dialog {
	.dialog_paper {
		background-color: transparent;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			z-index: 10;
			width: 340px;
			margin-bottom: 30px;
		}

		.content {
			z-index: 10;
			padding: 0 50px;
		}
	}

	.detail_dialog {
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		border-radius: 2rem !important;
		position: relative;
		height: 440px;
		width: 580px;
		margin-top: -412px;

		@include media($phone_all...) {
			width: 100%;
		}
	}

	h1 {
		letter-spacing: normal;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 46px;
		color: #fff;
		text-align: center;
		margin-bottom: 16px;
	}

	p {
		letter-spacing: normal;
		color: #fff;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
		text-align: center;

		@include media($phone_all...) {
			font-size: 16px;
		}

		span {
			color: #01b6df;
			font-weight: 600;
		}

		a {
			text-decoration: underline;
		}
	}

	.controls {
		display: flex;
		justify-content: center;
		margin-top: 36px;

		button {
			background-color: #4e4bde;
			border-radius: 25px;
			width: 106px;
			height: 35px;
		}
	}
}
