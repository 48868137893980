.contest_joined_dialog {
	padding: 5px 1px 20px 1px;
	.close_button {
		width: 100%;
		margin-top: 40px;
		color: #fff !important;
		padding: 4px !important;
		font-family: Titillium Web !important;
		font-size: 14px !important;
		font-weight: 700 !important;
		line-height: 24px !important;
		min-height: 40px;
		border-radius: 25px;
	}
	.header_title {
		margin-top: 32px;
	}
}
