.dialog {
	z-index: 10 !important;

	.dialog_paper {
		width: 367px;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		// background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		background: linear-gradient(
			180deg,
			rgba(45, 42, 180, 0.5) 0%,
			rgba(20, 18, 78, 0.5) 100%
		);

		.dialog_content {
			padding: 30px;

			h2 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 1.25rem;
				font-style: normal;
				font-weight: 700;
				line-height: 30px;
				letter-spacing: normal;
				margin-bottom: 10px;
			}
			h3 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 1rem;
				font-style: normal;
				font-weight: 700;
				line-height: 30px;
				letter-spacing: normal;
				margin-bottom: 10px;
			}
			p {
				letter-spacing: normal;
				color: #fff;
				opacity: 0.5;
				font-style: normal;
				margin-bottom: 15px;
				font-family: Titillium Web;
				font-size: 12px;
				font-weight: 300;
				line-height: 16px;
				text-align: center;
			}
			.flex_grid {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				.center {
					img {
						width: 99px;
						height: 91px;
						object-position: -332% 0%;
						object-fit: cover;
					}
				}
			}
			.first_Item {
				display: block;
				.box1 {
					padding: 5px;

					margin: 12px auto 16px auto;
				}
				.box2 {
					margin-bottom: 24px;
				}
			}
			h3.btn_close {
				margin: 0px !important;
			}
		}
	}

	&.warning {
		.dialog_paper {
			width: auto;
			overflow: visible;
		}

		img {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -90px;
			z-index: 10;
			width: 340px;
			height: 155px;
		}

		.dialog_content {
			overflow: auto;

			h2 {
				margin-top: 60px;
			}

			p {
				text-align: left;
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 30px;
			}
		}
	}
}
