@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.header {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	padding: 0 0 16px;
	font-weight: 600;

	@include media($phone_all...) {
		padding-right: 28px;
	}

	p,
	span {
		letter-spacing: normal;
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.25rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.otherHeader {
	position: relative;
	margin-top: 16px !important;
	margin-bottom: 16px !important;
	font-size: 9px !important;
	text-align: center;
	font-weight: 600 !important;

	&:before {
		position: absolute;
		content: '';
		height: 1px;
		width: 10px;
		background: #fff;
		top: 7px;
		left: 0px;
	}

	&:after {
		position: absolute;
		content: '';
		height: 1px;
		width: 10px;
		background: #fff;
		top: 7px;
		right: 0px;
	}
}

.otherLogin {
	margin-top: 24px;

	.wallet_cnct,
	.wallet_mobile_cnct {
		text-align: center;
		font-size: 0.8rem !important;
		border-radius: 25px !important;
		border: 0.1rem double transparent !important;
		background-image: radial-gradient(circle at left top, #fe9dd9, #8ee6e2),
			radial-gradient(circle at left top, #d900c0, #016eda) !important;
		background-origin: border-box !important;
		background-clip: padding-box, border-box !important;
		margin: 10px 0 15px 0;
		width: 100%;
		color: #000;
		font-size: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;

		svg {
			color: #000;
			font-size: 14px;
		}

		.walletBtn {
			position: relative;

			svg {
				color: #000;
				margin-right: 10px;
				font-size: 14px;
			}
		}

		&:disabled {
			opacity: 0.6;
			color: #000;
		}
	}

	.twitterCnct,
	.facebookCnct {
		display: none;
	}

	.wallet_mobile_cnct {
		display: block;
		font-size: 13px !important;
		color: #000 !important;
		font-weight: 600 !important;

		p {
			display: inline-block;
			font-size: 13px !important;
			font-weight: 600 !important;
			color: #000 !important;
		}

		img {
			width: 26px;
			width: 14px;
			margin-right: 10px;
			position: relative;
			top: 1px;
			display: inline;
		}

		@include media($phone_all...) {
			display: flex;
		}
	}
}

.content {
	padding: 0;

	.or {
		margin: 1rem;
		text-align: center;
	}

	.social_login {
		display: flex;
		justify-content: space-between;
	}

	.recaptcha {
		margin-bottom: 24px;

		> div:first-child {
			width: 100% !important;
		}

		iframe {
			width: 100% !important;
		}
	}

	.fields {
		.emailField {
			> div {
				background-color: transparent;
			}
		}

		input {
			width: 100%;
		}

		& > div {
			margin-bottom: 24px;
		}

		.password_container {
			display: flex;
			margin-top: -8px;

			.passwd_helper_text {
				margin-bottom: 0;
				font-size: 0.7rem;
				width: auto;
				padding: 0;
				margin-left: 1px;
				height: auto;
			}

			button {
				font-size: 12px;
				width: 10%;
				height: 50px;
				margin-left: 5px;
			}

			input {
				width: 100%;
			}
		}
	}

	.back {
		font-size: 0.9rem;
		font-weight: 600;
		background: #2e2c6a !important;
		border-radius: 25px !important;
		color: #fff !important;
		padding: 0.5rem 2.5rem !important;
		float: right;

		&:disabled {
			color: #ffffff96 !important;
		}
	}
}

.actions {
	justify-content: flex-start;
	margin-top: 2rem;

	p {
		font-size: 0.8rem;
		margin-top: 0;
		margin-bottom: 0;
		line-height: 1.3rem;
		color: #fff;
		font-weight: 600;

		span {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.metamaskbutton {
	font-size: 20px;
	margin: 10px 5px 15px;
	background: #f6851b !important;
	border: 2px solid rgba(255, 255, 255, 0.3);
	text-align: center;
	cursor: pointer;
	padding: 0.75rem 1.5rem;
	border-radius: 25px;
	color: #fff;
	display: none;

	&:hover {
		opacity: 0.75;
	}
}

.mobile {
	display: none;

	@include media($phone_all...) {
		display: inline-block !important;
	}
}

.agree {
	margin: 20px 30px 10px;

	@include media($phone_all...) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@include media($phone_all...) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	p {
		letter-spacing: normal;
		font-size: 10px;
		font-weight: 400;
		margin: 0;
		line-height: 18px;
		text-align: center;
		color: rgba(255, 255, 255, 0.5);
	}

	a {
		color: rgba(255, 255, 255, 0.5);
		cursor: pointer;
		text-decoration: underline;
	}
}
