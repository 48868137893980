#token-sale-widget-root {
	p {
		margin-top: 5px;
	}

	#main {
		section {
			padding-top: 0;
		}
	}
}

.widgetMain {
	margin: 0px 0 20px;
}

.widgetForm {
	padding-bottom: 25px;

	.buttonCTA,
	.purchaseNote {
		font-size: 15px;
		background: transparent;
		margin-bottom: 0;
		padding: 5px;
	}

	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	.max_button {
		color: green;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: underline;
	}

	button.buttonCTA {
		padding: 0.75rem;
	}

	.text-md {
		font-size: 20px !important;
		background: linear-gradient(135deg, #572c7c 0%, #572c7c 50%, #a034fa 100%);
		border: 2px solid rgba(255, 255, 255, 0.3);
	}

	.metamaskbutton {
		background: #f6851b !important;
		text-align: center;
	}
}

.tokenPrice {
	margin: 10px 0;

	p {
		font-size: 12px !important;
		padding-left: 8px;
		padding-right: 8px;
		margin-bottom: 10px;
	}
}

.tokenPrice:after {
	content: '';
	position: absolute;
	border-bottom: 0 transparent solid;
	z-index: -1;
	top: 36%;
	right: 0%;
	left: -100%;
	width: 300%;
}

@media only screen and (max-width: 768px) {
	.tokenPrice:after {
		left: -75%;
		width: 250% !important;
	}
}

.currentStage {
	margin-top: 0;
	display: none;
}

.header,
.widgetForm {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.widgetMain {
	margin: 0;
	padding-top: 10px;
	padding-bottom: 0;
	background: rgba(0, 0, 0, 0.35);
	max-width: 410px;
	width: 100%;
}

div:has(> input[type='number']:disabled) {
	opacity: 0.25;
}

.z-50 {
	z-index: 101 !important;
}
