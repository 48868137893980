.agreementDialogue {
	max-width: 700px;
}

.detect {
	margin: 30px 0 24px 0;
	border-radius: 5px;
	padding: 7px 10px;
	font-size: 17px;
	background-color: #979797;

	svg {
		font-size: 17px;
		margin-right: 5px;
	}
}

.danger {
	background-color: #bd1f40;
}

.success {
	background-color: #3cbb5b;
}

.header {
	.headline {
		margin-bottom: 10px;
	}

	p {
		font-size: 15px;
		line-height: 20px;
		margin-top: 20px;

		a {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	.country_list_text {
		cursor: pointer;
		color: #7cd3ff;
		font-weight: 600;
	}
}

.checkbox {
	display: block;
	margin-top: 10px;

	label {
		display: flex;
		align-items: baseline;
	}

	&.disabled {
		p,
		svg {
			color: #8b8b8b;
		}
	}

	span {
		display: inline-block;

		svg {
			position: relative;
			top: 2px;
			color: #fff;
		}
	}

	p {
		font-size: 12px;
		margin: 0;
	}
}

.footer {
	margin-top: 10px;
	text-align: center;

	button {
		margin-top: 10px;
		border-radius: 1.4rem;
		border: 0.1rem double transparent;
		background-image: linear-gradient(#000, #000),
			radial-gradient(circle at left top, #016eda, #d900c0);
		background-origin: border-box;
		background-clip: padding-box, border-box;
		padding: 0.5rem 2.5rem;
		color: #fff !important;
		margin: 4px;

		&:hover {
			background-image: linear-gradient(#152d53, #152d53),
				radial-gradient(circle at left top, #016eda, #d900c0);
			color: #fff;
		}

		&:disabled {
			opacity: 0.5;
		}
	}
}

.country_list {
	padding: 0 10px;

	li {
		padding: 3px 0;
		text-align: left;
		list-style: disc;
		font-size: 16px;
	}
}
