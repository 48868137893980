.title {
	text-align: left;
	color: #fff;
	font-weight: 500;
	font-size: 1.1rem;
	margin: 0 0 10px;
}

.iagree {
	label {
		font-size: 1rem;
		display: flex;
		align-items: center;
	}

	svg {
		font-size: 16px;
	}

	small {
		&.byCreate {
			font-size: 0.9rem;
			line-height: 1rem;

			a {
				color: #53abc9;
				font-weight: 500;
			}
		}
	}
}

.actions {
	button {
		margin-top: 0rem;
		border-radius: 0.4rem;
		background: #d006ab;
		padding: 0.5rem 1.25rem;
		color: #fff;
		border: none;
		font-size: 1rem;

		&:disabled {
			opacity: 0.35;
			color: #fff;
		}
	}
}
